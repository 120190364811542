const home = [
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/home'),
        meta: {
            title: '派输注',
            needSecondJump: true,
        },
    },
    // {
    //     path: '/search-result/:type/:title?/:tid?',
    //     name: 'search-result',
    //     component: () => import(/* webpackChunkName: "search_result" */ '@/pages/home/searchResult'),
    //     meta: {
    //         title: '派输注',
    //     },
    // },
];

export default home;
