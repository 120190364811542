import fetch from '@/utils/fetch';

export default {
    login: (params) => fetch({
        url: '/api/pi-infusion/v1/app/open/user/login',
        params,
        method: 'post',
        loading: true,
        independent: true,
    }),

    // 发送短信验证码
    sendSMS: (params) => fetch({
      url: '/api/pi-infusion/v1/app/open/user/sendMsg',
      params,
      method: 'post',
      loading: true,
    }),

    // 退出
    logout: (params) => fetch({
      url: '/api/pi-infusion/v1/app/open/user/logout',
      params,
      loading: true,
    }),

    // 注销账号
    logoff: (params) => fetch({
      url: '/api/pi-infusion/v1/app/patient/account/cancel',
      params,
      loading: true,
    }),
};
