const guidance = [
    {
        path: '/search-center',
        name: 'search-center',
        component: () => import(/* webpackChunkName: "guidance" */'@/pages/search-center/index.vue'),
        meta: {
            title: '搜索输注中心',
        },
    },
];

export default guidance;
