const centerDetail = [
    {
        path: '/center-detail',
        name: 'center-detail',
        component: () => import(/* webpackChunkName: "guidance" */'@/pages/center-detail/index.vue'),
        meta: {
            title: '输注中心详情',
        },
    },

];

export default centerDetail;
