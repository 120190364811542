const middle = [
  {
      path: '/middle',
      name: 'middle',
      component: () => import(/* webpackChunkName: "middle" */ '@/pages/middle/index.vue'),
      meta: {
          title: '预约记录',
      },
  }
];

export default middle;
