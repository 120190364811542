import home from './home';
import drug from './drug';
import common from './common';
import cityService from './city-service';
import logout from './login';
import infusion from './infusion'
import apply from './apply'
import center from './center'

const apis = {
    ...home,
    ...drug,
    ...common,
    ...cityService,
    ...logout,
    ...infusion,
    ...apply,
    ...center,
};
export default apis;
