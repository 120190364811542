import fetch from '@/utils/fetch';

export default {
  // 取消预约
  cancelApply: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/cancel',
    params,
    method: 'post',
    loading: true,
  }),

  // 预约tab统计
  applyCount: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/count',
    params,
    method: 'post',
    loading: true,
  }),

  // 预约详情
  applyDetail: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/detail',
    params,
    method: 'get',
    loading: true,
  }),

  // 预约记录
  applyList: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/list',
    params,
    method: 'post',
    loading: true,
  }),

  // 获取预约输注时间
  getAvaibleApplyTime: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/reservationDate',
    params,
    loading: true,
    // method: 'post',
  }),

  // 预约申请
  applySave: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/save',
    params,
    method: 'post',
    loading: true,
  }),

  applyTemporarySave: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/temporary/save',
    params,
    method: 'post',
    loading: true,
  }),
  // 获取缓存的申请信息
  getTemporarySavedApplyInfo: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/findBy/user/infusionCenterCode',
    params,
    loading: true,
  }),

  // 问卷提交
  surveySave: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/survey/save',
    params,
    method: 'post',
    loading: true,
  }),
  surveyDetail: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/survey/detail',
    params,
    // method: 'post',
    loading: true,
  }),
  getPharmacyList: (params) => fetch({
    url: '/api/pi-infusion/v1/app/open/infusion/pharmacy/list',
    params,
    method: 'post',
    loading: true,
  }),
  getApplyGoods: (params) => fetch({
    url: '/api/pi-infusion/v1/app/open/infusion/goods/page',
    params,
    method: 'post',
    loading: true,
  }),

  // 保存资料图片
  saveImages: (params) => fetch({
    url: '/api/pi-infusion/v1/app/patient/saveImages',
    params,
    method: 'post',
    loading: true,
  }),
};
