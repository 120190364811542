const selectMedicine = [
  {
      path: '/selectMedicine',
      name: 'selectMedicine',
      component: () => import(/* webpackChunkName: "selectMedicine" */ '@/pages/selectMedicine/index.vue'),
      meta: {
          title: '选择药品',
      },
  }
];

export default selectMedicine;
