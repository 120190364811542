import Vue from 'vue';
import VueRouter from 'vue-router';
import Configs from '@/config';
import home from './home';
import city from './city';
import centerDetail from './center-detail';
import login from './login';
import searchCenter from './search-center';
import preengage from './preengage';
import infusionCenter from './infusion-center';
import preengageDetail from './preengage-detail';
import usercenter from './usercenter';
import instructions from './instructions';
import appointmentApply from './appointment-apply';
import cancelApply from './cancelApply';
import serviceJudge from './serviceJudge';
import infusion from './infusion-center';
import applyList from './applyList';
import middle from './middle';
import selectMedicine from './selectMedicine';
import searchMedicine from './searchMedicine';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '@/pages/index'),
    redirect: '/home',
  },
  ...home,
  ...city,
  ...login, // 登录
  ...searchCenter,
  ...preengage,
  ...centerDetail, // 输注中心详情
  ...infusionCenter, // 输注中心详情
  ...preengageDetail, // 预约详情
  ...usercenter, // 用户中心
  ...instructions, // 流程介绍
  ...appointmentApply, // 预约申请
  ...cancelApply, // 取消申请
  ...serviceJudge, // 评价
  ...infusion,
  ...applyList,
  ...middle,
  ...selectMedicine,
  ...searchMedicine,
];

const router = new VueRouter({
  mode: 'history',
  routes,
  // base: '/yf/',
});

const isUserLoggedIn = () => {
  // 没有token， 或者token过期
  if (!localStorage.getItem('userToken')) {
    return false;
  }
  return true;
};

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.matched.some((record) => record.meta.requiresAuth) && !isUserLoggedIn()) {
    // 如果是需要登录鉴权的页面，并且用户未登录，则重定向到登录页面
    next({
      path: '/login',
      replace: true,
      // query: { redirect: to.fullPath } // 将目标路由作为重定向参数传递
    });
  } else {
    // 如果用户已登录或不需要登录，正常导航
    next();
  }
});

router.afterEach((to) => {
  console.log('after Each', to);
  const url = `${window.location.origin}${to.fullPath}`;
  const u = navigator.userAgent;
  if (u.indexOf('iPhone') > -1 || u.indexOf('iOS') > -1) {
    if (window.configUrl === '' || window.configUrl === undefined) {
      window.configUrl = url;
    }
  } else {
    window.configUrl = url;
  }
});

export default router;
