const instructions = [
  {
    path: '/instructions',
    name: 'instructions',
    component: () => import(/* webpackChunkName: "instructions" */ '@/pages/instructions'),
    meta: {
      title: '流程说明',
    },
  },
];

export default instructions;
