/* eslint-disable no-case-declarations */
import Axios from 'axios';
import Configs from '@/config';
import { Toast } from 'vant';
import clientApiRoutePrefix from '../api/prefix';

const fetch = async (config) => {
  const {
    method, params, independent, url, timeout, headers: headerInfo, loading = false, cancelToken, ...other
  } = config;
  const token = localStorage.getItem('userToken') || undefined;
  let headers = null;
  if (url.indexOf('/server/') >= 0) {
    headers = {
      ...headerInfo,
    };
  } else {
    console.log('token', token);
    headers = {
      token,
      ...headerInfo,
    };
  }

  // 创建axios实例
  const axiosIns = Axios.create();
  let options = {
    headers,
    baseURL: Configs.baseUrl,
    url,
    ...other,
    timeout: timeout || 1 * 60 * 1000,
  };

  let cancel = null;
  if (method === 'post') {
    options = {
      ...options,
      method: 'post',
      data: params,
      cancelToken: new Axios.CancelToken(((c) => {
        cancel = c;
      })),
    };
  } else {
    options = {
      ...options,
      method: 'get',
      params,
      cancelToken: cancelToken || null,
    };
  }
  const apiArr = ['/order/create', '/order/cancel', '/order/createPay', `${clientApiRoutePrefix}/comment/add`, '/order/list'];
  for (let i = 0; i < apiArr.length; i++) {
    if (url.indexOf(apiArr[i]) !== -1) {
      // console.log(params.count)
      if (params.count > 1) {
        cancel('just a test');
      }
      delete params.count;
    }
  }
  if (loading) {
    Toast.loading({
      message: '加载中...',
      forbidClick: false,
      loadingType: 'spinner',
      duration: 30 * 1000,
    });
  }

  const response = await axiosIns(options)
    .then((res) => {
      // 只要有返回结果，关闭toast（加载中）提示
      if (loading) Toast.clear();
      const { code, message } = res.data;
      // independent 存在做单独异常处理，其余走统一业务处理
      if (independent) return res.data;

      if (String(code) === '401') {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userToken');
        Toast.fail('登录已失效');
        window.location.href = `${Configs.loginUrl}`;
        return false;
      }

      if (code !== '0') {
        // code不为0视为错误
        Toast.fail({
          duration: 2000,
          message,
        });
        // return false;
      }

      return res.data;
    })
    .catch((error) => {
      if (loading) Toast.clear();

      const { message, response: resInfo } = error;
      if (/timeout\sof\s\d+ms\sexceeded/.test(message)) {
        Toast.fail('网络不稳定，请稍后重试');
      }

      if (resInfo) {
        switch (resInfo.status) {
          case 404:
            Toast.fail('请求的资源不存在');
            break;
          case 500:
            Toast.fail('服务异常，请稍后重试');
            break;
          case 503:
            Toast.fail('服务器正在维护，请稍后重试');
            break;
          case 401:
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userToken');
            Toast.fail('登录已失效');
            window.location.href = `${Configs.loginUrl}`;
            break;
          default:
        }
      }
      return false;
    });
  return response;
};

export default fetch;
