import fetch from '@/utils/fetch';

export default {
  // 获取签名信息
  getSignatureInfo: (params) => fetch({
    url: '/management-system/api/v1/wechat/signature',
    params,
    method: 'post',
    loading: false,
  }),
};
