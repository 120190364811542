const appointmentApply = [
  {
      path: '/appointmentApply',
      name: 'appointmentApply',
      component: () => import(/* webpackChunkName: "appointmentApply" */'@/pages/appointmentApply/index.vue'),
      meta: {
          title: '提交预约',
          requiresAuth: true,
          // keepAlive: true,
      },
  },

  {
    path: '/appointmentApply/applyDone',
    name: 'applyDone',
    component: () => import(/* webpackChunkName: "appointmentApply" */'@/pages/appointmentApply/applyDone.vue'),
    meta: {
        title: '提交预约',
    },
  }

];

export default appointmentApply;
