const preengageDetail = [
  {
      path: '/preengage-detail',
      name: 'preengage-detail',
      component: () => import(/* webpackChunkName: "guidance" */'@/pages/preengage-detail/index.vue'),
      meta: {
          title: '预约详情',
          requiresAuth: true
      },
  },
];

export default preengageDetail;
