const searchMedicine = [
  {
      path: '/searchMedicine',
      name: 'searchMedicine',
      component: () => import(/* webpackChunkName: "searchMedicine" */ '@/pages/searchMedicine/index.vue'),
      meta: {
          title: '选择药品',
      },
  }
];

export default searchMedicine;
