const serviceJudge = [
  {
      path: '/serviceJudge',
      name: 'serviceJudge',
      component: () => import(/* webpackChunkName: "serviceJudge" */ '@/pages/serviceJudge'),
      meta: {
          title: '满意度调查表',
          requiresAuth: true
      },
  },
];

export default serviceJudge;
