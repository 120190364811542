const applyList = [
  {
    path: '/applyList',
    name: 'applyList',
    component: () => import(/* webpackChunkName: "applyList" */ '@/pages/applyList'),
    meta: {
      title: '预约申请列表',
      requiresAuth: true
    },
  }
];

export default applyList;
