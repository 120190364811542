import wx from 'weixin-js-sdk';
import { Toast } from 'vant';
import api from '@/api';
import QQMapWX from '@/assets/tencent_map/qqmap-wx-jssdk';
import Configs from '@/config';

const qqMapObj = new QQMapWX({
  key: 'CMBBZ-JHIR4-YTKUD-DBOHZ-NQ5X6-2IFWK', // 自己的key秘钥 http://lbs.qq.com/console/mykey.html 在这个网址申请
});
class WxTool {
  constructor () {
    console.log('WxTool init');
  }

  static getInstance () {
    if (!WxTool.instance) {
      WxTool.instance = new WxTool();
    }
    return WxTool.instance;
  }

  static async initConfig () {
    await api.getSignatureInfo({
      url: window.configUrl,
      source: Configs.source,
    }).then((res) => {
      const {
        appId, timestamp, nonceStr, signature,
      } = res.data;

      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: [
          'chooseImage',
          'getLocation',
          'openLocation',
          'getLocalImgData',
        ], // 必填，需要使用的JS接口列表
      });
    });
  }

  async getLocation (successCb) {
    console.log('getLocation');
    await WxTool.initConfig();

    const timer = setTimeout(() => {
      Toast('定位失败，请手动选择城市');
      if (successCb && typeof successCb === 'function') {
        successCb({
          latitude: '',
          longitude: '',
        });
      }
    }, 5 * 1000);

    await wx.ready(() => {
      wx.checkJsApi({
        jsApiList: ['getLocation', 'openLocation'],
        success (res) {
          console.log('res, ready', res, 'jjj', JSON.stringify(res));
          if (!res.checkResult.getLocation) {
            Toast('未开启定位权限');
          } else {
            wx.getLocation({
              type: 'wgs84',
              success (locationRes) {
                console.log('getLocation success', locationRes);
                clearTimeout(timer);
                if (successCb && typeof successCb === 'function') {
                  successCb(locationRes);
                }
              },
              fail (err) {
                console.log(err, '----- getLocation error -----');
                // Toast('定位失败，请手动选择城市');
                if (successCb && typeof successCb === 'function') {
                  successCb({
                    latitude: '',
                    longitude: '',
                  });
                }
              },
              cancel () {
                console.log('cancel');
                clearTimeout(timer);
              },
              complete () {
                console.log('complete');
                clearTimeout(timer);
              },
            });
          }
        },
        fail (err) {
          console.log(err);
        },
      });

      // wx.getLocation({
      //   type: 'wgs84',
      //   success(res) {
      //     console.log('getLocation success', res)
      //     clearTimeout(timer);
      //     if (successCb && typeof successCb === 'function') {
      //       successCb(res);
      //     }
      //   },
      //   fail(err) {
      //     console.log(err, '----- getLocation error -----');
      //     // Toast('定位失败，请手动选择城市');
      //     if (successCb && typeof successCb === 'function') {
      //       successCb({
      //         latitude: '',
      //         longitude: '',
      //       });
      //     }
      //   },
      //   cancel() {
      //     console.log('cancel');
      //     clearTimeout(timer);
      //   },
      //   complete() {
      //     console.log('complete');
      //     clearTimeout(timer);
      //   },
      // });
      // wx.error((err) => {
      //     // Toast('接口调用失败');
      //     console.log(err, '----- getLocation error -----');
      // });
    });
  }

  /**
   * 通过经纬度获取城市
   * @param {经度} latitude
   * @param {纬度} longitude
   * @param {回调} callBack
   */
  getCurrentCity (latitude, longitude, callBack) {
    qqMapObj.reverseGeocoder({
      latitude,
      longitude,
      success: (res) => {
        console.log('qqMapObj', res);
        const {
          city_code: cityCode,
          nation_code: nationCode,
        } = res.result.ad_info;
        const cityName = res.result.address_component.city;
        console.log('reverseGeocoder', cityName);
        if (callBack && typeof callBack === 'function') {
          callBack(cityName);
        }
      },
      fail: (fail) => {
        console.log('fail', fail);
      },
    });
  }

  // 选图
  async chooseImage (num, callback) {
    console.log(num, '172');
    await WxTool.initConfig();
    wx.ready(() => {
      wx.checkJsApi({
        jsApiList: ['chooseImage'],
        success () {
          wx.chooseImage({
            count: num, // 默认9
            sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
            sourceType: ['camera', 'album'], // 可以指定来源是相册还是相机，默认二者都有
            success (res) {
              console.log(res, '182');
              if (typeof callback === 'function') {
                callback(res);
              }
            },
            fail (err) {
              console.log(err, '182');
              if (typeof failCb === 'function') {
                callback(err);
              }
            },
          });
        },
        fail (err0) {
          console.log(err0);
        },
      });
    });
  }

  // 获取本地图片接口
  async getLocalImgData (localId, callback) {
    await WxTool.initConfig();
    wx.ready(() => {
      wx.checkJsApi({
        jsApiList: ['getLocalImgData'],
        success () {
          wx.getLocalImgData({
            localId,
            success: (res) => {
              console.log(res, '230getLocalImgDatagetLocalImgData');
              callback(res.localData);
            },
            fail (err) {
              callback(err);
            },
          });
        },
        fail (err0) {
          console.log(err0, '241');
        },
      });
    });
  }

  // 导航
  async navigate (latitude, longitude) {
    await WxTool.initConfig();

    wx.ready(() => {
      wx.checkJsApi({
        jsApiList: ['openLocation'],
        success (res) {
          console.log('res, ready', res, 'jjj', JSON.stringify(res));
          if (!res.checkResult.openLocation) {
            Toast('未开启定位权限');
          } else {
            wx.openLocation({
              latitude,
              longitude,
              success () {

              },
              fail () {

              },
            });
          }
        },
        fail (err) {
          console.log(err);
        },
      });
    });
  }
}

export default WxTool;
