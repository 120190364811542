const preengage = [
    {
        path: '/preengage',
        name: 'preengage',
        component: () => import(/* webpackChunkName: "guidance" */'@/pages/preengage/index.vue'),
        meta: {
            title: '预约输注',
        },
    },
];

export default preengage;
