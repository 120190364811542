const cancelApply = [
  {
      path: '/cancelApply',
      name: 'cancelApply',
      component: () => import(/* webpackChunkName: "cancelApply" */'@/pages/cancelApply'),
      meta: {
          title: '取消预约',
      },
  }
];

export default cancelApply;
