import fetch from '@/utils/fetch';

export default {
  // 中心审核端：预约详情
  centerApplyDetail: (params) => fetch({
    url: '/api/pi-infusion/v1/app/center/detail',
    params,
    loading: true,
  }),

  // 中心审核端：审核预约
  centerApplyAudit: (params) => fetch({
    url: '/api/pi-infusion/v1/app/center/audit',
    params,
    method: 'post',
  }),

  // 输注中心预约记录
  getCenterList: (params) => fetch({
    url: '/api/pi-infusion/v1/app/center/list',
    method: 'post',
    params,
    loading: true,
  }),

  // 输注中心预约数量统计
  getCenterCount: (params) => fetch({
    url: '/api/pi-infusion/v1/app/center/count',
    method: 'post',
    params,
  }),
};
