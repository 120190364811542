import fetch from '@/utils/fetch';

export default {
// 输注中心详情
  getInfusionDetail: (params) => fetch({
    url: '/api/pi-infusion/v1/app/open/infusion/detail',
    params,
    loading: true,
  }),

  // 输注中心列表
  getInfusionList: (params) => fetch({
    url: '/api/pi-infusion/v1/app/open/infusion/page',
    params,
    method: 'post',
    loading: true,
  }),

  /**
 * 图片批量上传  /api/pi-infusion/v1/app/common/file/uploadBatch
 */
  uploadFilePre: (file) => fetch({
    url: '/api/pi-infusion/v1/app/open/common/file/uploadBatch',
    params: file,
    method: 'post',
    loading: false,
  }),
};
