const login = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/login/index'),
        meta: {
            title: '登录',
        },
    },
];

export default login;
