/*
 * @Author: ajx
 * @Email: jianxin.an@medbanks.cn
 * @Date: 2020-11-10 15:53:00
 * @Last Modified by: chenjie
 * @Last Modified time: 2023-11-03 16:29:30
 * @Description: Description
 * @Route: Route
 */
const infusion = [
  {
    path: '/infusion',
    name: 'infusion',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/infusion'),
    meta: {
      title: '预约记录',
      requiresAuth: true
    },
  },
  {
    path: '/infusion/detail',
    name: 'InfusionCenterDetail',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/infusion/detail'),
    meta: {
      title: '预约审核',
      requiresAuth: true
    },
  },
];

export default infusion;
