import Vue from 'vue';
import VConsole from 'vconsole';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import {
  Lazyload, Swipe, SwipeItem, RadioGroup, Radio, List, PullRefresh, Button, CellGroup, Field, Form, Checkbox, CheckboxGroup, Badge, Popup, Picker, Dialog, Toast, Search, Icon, SwipeCell, Tabs, Tab, ActionSheet, ImagePreview,
} from 'vant';
import fetch from '@/utils/fetch';
import router from './router';
import store from './store';
import 'lib-flexible/flexible';
import './assets/styles/base';
import './assets/styles/variables';
import '@/directives';
import '@/filters';
import '../mock';

// eslint-disable-next-line no-new
if ((process.env.NODE_ENV === 'production' && process.env.VUE_APP_ISUAT) || process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-new
  new VConsole();
} else {
  Sentry.init({
    dsn: '',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

Vue.prototype.$fetch = fetch;
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Button);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Form);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Badge);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Search);
Vue.use(Icon);
Vue.use(SwipeCell);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(ActionSheet);
Vue.use(ImagePreview);

function handleFontSize () {
  // 设置网页字体为默认大小
  window.WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 });
  // 重写设置网页字体大小的事件
  window.WeixinJSBridge.on('menu:setfont', () => {
    window.WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 });
  });
}
if (typeof WeixinJSBridge === 'object' && typeof window.WeixinJSBridge.invoke === 'function') {
  handleFontSize();
} else {
  document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
}

new Vue({
  router,
  store,
}).$mount('#app'); // 手动挂载
