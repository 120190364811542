const usercenter = [
  {
    path: '/usercenter',
    name: 'usercenter',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/usercenter'),
    meta: {
        title: '个人中心',
        requiresAuth: true
    },
  },
  {
    path: '/usercenter/logoff',
    name: 'logoff',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/usercenter/logoff'),
    meta: {
        title: '平台账户注销须知',
    },
  }
];

export default usercenter;
