import fetch from '@/utils/fetch';

export default {
    // 获取常用药品
    getDrugs: (params) => fetch({
        url: `/api/pi-infusion/v1/app/open/infusion/product/list`,
        params,
        method: 'post',
        loading: true,
    }),
};
